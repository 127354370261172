/**
 * Based on https://code.amazon.com/packages/ApeVideoTemplate/blobs/a1701dfcc36459a47b09005ef921d91fe484deb4/--/src/module/network-connection-info.js
 *
 * NetworkInformation: https://developer.mozilla.org/en-US/docs/Web/API/NetworkInformation
 * MASH: https://w.amazon.com/bin/view/MASH/Application_Context_Protocol/1.5
 */

const DEFAULT_RESPONSE_TO_UNKNOWN_DOWNLINK_SPEED = false;

/**
 * HSPA+ is considered a fast mobile connection type despite being 3G. According to
 * https://commsbrief.com/mobile-data-speed-with-2g-3g-4g-and-5g-cellular-networks/
 * HSPA+ has average download speed of 5 - 8 Mbits/second. Assuming HSPA+ is the slowest
 * of the "fast" connection types, the minimum download speed required to be
 * considered a fast connection is 5 Mbits/second.
 */
const MIN_MEGABITS_PER_SECOND_FOR_FAST_CONNECTION = 5;

export const CONNECTION_TYPE_UNKNOWN = 'unknown';

export const MOBILE_CONNECTION_TYPE_UNKNOWN = 0;

/**
 * https://developer.mozilla.org/en-US/docs/Web/API/NetworkInformation/saveData
 * @returns {boolean} true if user has set a reduced data usage option on the user agent; false otherwise
 */
export const isSaveDataEnabled = () => {
    const saveData: boolean | undefined = navigator?.connection?.saveData;
    return saveData ? saveData : false;
};

/**
 * https://developer.mozilla.org/en-US/docs/Web/API/NetworkInformation/downlink
 * @returns {boolean} true if downlink speed is greater than 5 Megabits per second; false otherwise
 */
export const isSlowConnectionMobile = () => {
    const downlink = navigator?.connection?.downlink;
    if (downlink) {
        return downlink < MIN_MEGABITS_PER_SECOND_FOR_FAST_CONNECTION;
    }
    return DEFAULT_RESPONSE_TO_UNKNOWN_DOWNLINK_SPEED;
};
