import type { MobileAdsInfo, MobileSFClientAPI } from '@amzn/safe-frame-client-mobilesfclient';
import { MashAPIImpl } from './Mash';
import { CommonApiImplementation } from './CommonApiImplementation';
import { ClientMessageSender } from './clientMessageSender';
import type { AdDetails } from '../../@types/adCommon';
import { ClientMessageListeners } from './clientMessageReceiver';
import type { MobileSupportedCommands } from '../host/MobileSupportedCommands';
import { getUserNetworkConnectionInfo } from '../host/Mash';

export class MobileSFClientAPIImplementation extends CommonApiImplementation implements MobileSFClientAPI {
    readonly mash: MashAPIImpl;

    constructor(
        cms: ClientMessageSender,
        o: AdDetails,
        mL: ClientMessageListeners,
        forceRenderFallbackExperience: () => void,
    ) {
        super(cms, o, mL, forceRenderFallbackExperience);
        this.mash = new MashAPIImpl(this, cms, o);
    }
    onPageEvent = {
        callback: () => {
            /**/
        },
        execute: (eventName: string, callback: () => void) => {
            this.logAPIInvocation('onPageEvent');
            this.onPageEvent.callback = callback;
            this.cms.sendMessage<MobileSupportedCommands['onPageEvent']>('onPageEvent', { eventName: eventName });
        },
        update: () => {
            if (typeof this.onPageEvent.callback === 'function') {
                this.onPageEvent.callback();
            }
        },
    };

    /**
     * @func sendAdInfo
     * @desc [Internal & External] Sends information about creative ID / program ID to page, to send to AdFeedback team
     * @param {*} mobileAdsInfo - Information passed in by the creatives
     */
    sendAdInfo = (mobileAdsInfo: MobileAdsInfo) => {
        if (mobileAdsInfo) {
            this.cms.sendMessage<MobileSupportedCommands['getAdInfo']>('getAdInfo', { mobileAdsInfo: mobileAdsInfo });
        }
    };
    getPlacementInfo = () => {
        this.logAPIInvocation('getPlacementInfo');
        const placementInfo = {
            pageType: this.o.pageType as string,
            slotName: this.o.slotName as string,
            subPageType: this.o.subPageType as string,
        };
        return placementInfo;
    };
    // TODO Move this to public API
    getUserAgentInfo = (): string => {
        // TODO Eliminate the override here
        return window.navigator.userAgent;
    };
    /**
     * @deprecated Do not use this anymore.  Use direct APIs

     * @desc Returns the amzn-app-ctxt cookie if it exists
     * @returns {String} cookie - amzn-app-ctxt
     */
    getDocumentCookie = () => {
        this.logAPIInvocation('getDocumentCookie');
        const amaznAppContextCookieValue = this.o.amznAppContext ? `amzn-app-ctxt=${this.o.amznAppContext}` : undefined;
        return amaznAppContextCookieValue ?? '';
    };

    /**
     * TODO: deprecate this and replace it with shouldHaveAutoplayPermissionFromDevice
     * https://sim.amazon.com/issues/CPP-30396
     */
    getUserConnectionInfo = () => {
        this.logAPIInvocation('getUserConnectionInfo');
        return getUserNetworkConnectionInfo(this.o.amznAppContext);
    };
}
