import { convertToStringIfBase64 } from '../components/util';
import { MobileSFClientAPIImplementation } from './MobileSFClientAPIImplementation';
import type { AdDetails } from '../../@types/adCommon';
import { ClientMessageSender } from './clientMessageSender';
import { CommonSFClientSetup } from './CommonSFSetup';
import type { SetupVideoPlayer } from './video';

export class MSFClientSetup extends CommonSFClientSetup<MobileSFClientAPIImplementation> {
    constructor(o: AdDetails, messagePort: MessagePort, setupVideoPlayer?: SetupVideoPlayer) {
        const cms = new ClientMessageSender(o, messagePort);
        const mL = {};
        super(
            o,
            messagePort,
            mL,
            cms,
            new MobileSFClientAPIImplementation(cms, o, mL, () => this.forceRenderFallbackExperience()),
            setupVideoPlayer,
        );
    }

    protected renderFallbackStaticAdHelper() {
        // TODO: refactor renderFallbackExperience and forceRenderFallbackExperience to hoist to top level. Note one line
        //  difference between desktop and mobile https://code.amazon.com/reviews/CR-91336113/revisions/2#/details
        document.head.insertAdjacentHTML('beforeend', `<style>body{ line-height:inherit; margin:0; }</style>`);
    }

    protected loadAdPlatformSpecific(htmlContent: string): string {
        /**
         * Encore placements require styling applied inside the iframe in a wrapper div.
         * This is a short-term fix. The creativeWrapper div is to be removed for all placements in future. Refer TT: https://tt.amazon.com/0091651603
         */
        if (this.o.creativeWrapperDivEncoded) {
            const creativeWrapperDiv = convertToStringIfBase64(this.o.creativeWrapperDivEncoded);
            const creativeInsertPosition = creativeWrapperDiv.lastIndexOf('</div>');
            return [
                creativeWrapperDiv.slice(0, creativeInsertPosition),
                htmlContent,
                creativeWrapperDiv.slice(creativeInsertPosition),
            ].join('');
        }

        return htmlContent;
    }
}
